
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "SSOLoginFailed",
  components: {},
})
export default class SSOLoginFailed extends Vue {
  toLogin() {
    this.$router.replace({ path: "login" });
  }
  mounted() {}
}
