import { render, staticRenderFns } from "./SSOLoginFailed.vue?vue&type=template&id=538d05ef&scoped=true&"
import script from "./SSOLoginFailed.vue?vue&type=script&lang=ts&"
export * from "./SSOLoginFailed.vue?vue&type=script&lang=ts&"
import style0 from "./SSOLoginFailed.vue?vue&type=style&index=0&id=538d05ef&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "538d05ef",
  null
  
)

export default component.exports